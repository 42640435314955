import {
  USER_ACCESS_TOKEN_KEY,
  ADMIN_ACCESS_TOKEN_KEY,
  ADMIN_REFRESH_TOKEN_KEY,
  USER_REFRESH_TOKEN_KEY,
  USER_SAVE_SESSION,
  ADMIN_SAVE_SESSION
} from 'src/constants/auth';
import { ApiPath } from 'src/enums/apiPath';
import { axiosAdmin, axiosUser } from 'src/utils/axios';
import { LoginFormI } from 'src/models/auth';
import { RoutePath } from 'src/enums/routePath';
import { removeToken, setSessionItem, setStorageItem } from 'src/utils/storage';

export const fetchMeUser = () => {
  return axiosUser.get('/user/me');
};

export const loginUser = async ({ isSaveSession, ...payload }: LoginFormI) => {
  const res = await axiosUser.post(ApiPath.LOGIN_USER, payload);

  const accessToken = res?.data?.access_token;
  const refreshToken = res?.data?.refresh_token;

  setStorageItem(USER_SAVE_SESSION, `${isSaveSession}`);
  if (!!accessToken) {
    if (isSaveSession) {
      setStorageItem(USER_ACCESS_TOKEN_KEY, accessToken);
      setStorageItem(USER_REFRESH_TOKEN_KEY, refreshToken);
    } else {
      setSessionItem(USER_ACCESS_TOKEN_KEY, accessToken);
      setSessionItem(USER_REFRESH_TOKEN_KEY, refreshToken);
    }
  }

  return res;
};

export const forgotPassword = async ({
  ...payload
}: {
  email: string;
  reset_link: string;
}) => {
  const res = await axiosUser.post(ApiPath.FORGOT_USER, payload);
  return res;
};

export const resetPassword = async ({
  ...payload
}: {
  password: string;
  token: string;
}) => {
  const res = await axiosUser.post(ApiPath.RESET_USER, payload);
  return res;
};

export const logoutUser = () => {
  removeToken(USER_ACCESS_TOKEN_KEY);
  removeToken(USER_REFRESH_TOKEN_KEY);
  window.location.replace(RoutePath.LOGIN);
};

export const fetchMeAdmin = () => {
  return axiosAdmin.get('/user/me');
};

export const loginAdmin = async ({ isSaveSession, ...payload }: LoginFormI) => {
  const res = await axiosAdmin.post(ApiPath.LOGIN_ADMIN, payload);

  const accessToken = res?.data?.access_token;
  const refreshToken = res?.data?.refresh_token;

  setStorageItem(ADMIN_SAVE_SESSION, `${isSaveSession}`);
  if (!!accessToken) {
    if (isSaveSession) {
      setStorageItem(ADMIN_ACCESS_TOKEN_KEY, accessToken);
      setStorageItem(ADMIN_REFRESH_TOKEN_KEY, refreshToken);
    } else {
      setSessionItem(ADMIN_ACCESS_TOKEN_KEY, accessToken);
      setSessionItem(ADMIN_REFRESH_TOKEN_KEY, refreshToken);
    }
  }

  return res;
};

export const logoutAdmin = () => {
  removeToken(ADMIN_ACCESS_TOKEN_KEY);
  removeToken(ADMIN_REFRESH_TOKEN_KEY);
  window.location.replace(RoutePath.ADMIN_LOGIN);
};
