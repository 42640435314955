/* eslint-disable no-unused-vars */
export enum RoutePath {
  ALL = '/*',
  LOGIN = '/login',
  NOT_FOUND = '/not-found',
  BUILDING = '/building',
  PUBLIC = '/public',
  HOME = '/',
  ADMIN = '/admin',
  ADMIN_LOGIN = '/admin/login',
  ADMIN_BUILDING = '/admin/building',
  ADMIN_CUSTOMER = '/admin/customer',
  ADMIN_USER = '/admin/user',
  CREATE = 'create',
  EDIT = 'edit',
  RECOVER_PASSWORD = '/recover-password',
  RESET_PASSWORD = '/reset-password'
}
