/* eslint-disable no-unused-vars */
export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_IMAGE = process.env.REACT_APP_IMAGE_API_URL;

export const URL_CUSTOMER = process.env.REACT_APP_VIEW_APP_URL;

export enum ApiPath {
  USER = '/admin',
  LOGIN_USER = '/auth/login/user',
  FORGOT_USER = '/auth/forgot-password',
  RESET_USER = '/auth/reset-password',
  LOGIN_ADMIN = '/auth/login/admin',
  REFRESH_TOKEN = '/auth/refresh',
  CUSTOMER = '/user',
  ALL_CUSTOMER = '/user/all',
  BUILDING = '/buildings',
  ALL_BUILDING = '/buildings/all',
  // CUSTOMER_BUILDING = '/buildings/',
  UPLOAD = '/uploads/upload-image',
  S3_UPLOAD = '/s3/upload'
}
