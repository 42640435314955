import MainLayout from 'src/layouts/MainLayout';
import { lazy } from 'react';
import { RouteI } from 'src/models/router';
import { RoutePath } from 'src/enums/routePath';
import { Outlet } from 'react-router-dom';
import CustomerLayout from 'src/layouts/CustomerLayout';

const LoginPage = import('../pages/User/Login');
const RecoverPasswordPage = import('../pages/User/RecoverPassword');
const ResetPasswordPage = import('../pages/User/ResetPassword');

const BuildingPage = import('../pages/User/Building');
const HomePage = import('../pages/User/Home');

//admin
const LoginAdmin = import('../pages/Admin/Login');
const BuildingAdmin = import('../pages/Admin/Building');
const CustomerAdmin = import('../pages/Admin/Customer');
const User = import('../pages/Admin/User');

export const routes: RouteI[] = [
  //user
  {
    path: RoutePath.LOGIN,
    Component: lazy(() => LoginPage),
    isUser: true
  },
  {
    path: RoutePath.RECOVER_PASSWORD,
    Component: lazy(() => RecoverPasswordPage),
    isUser: true
  },
  {
    path: RoutePath.RESET_PASSWORD,
    Component: lazy(() => ResetPasswordPage),
    isUser: true
  },
  {
    path: RoutePath.PUBLIC,
    Component: lazy(() => BuildingPage),
    Layout: <CustomerLayout />
  },
  {
    path: RoutePath.BUILDING,
    Component: lazy(() => BuildingPage),
    Layout: <CustomerLayout />,
    isPrivate: true,
    isUser: true
  },
  {
    path: RoutePath.HOME,
    Component: lazy(() => HomePage),
    Layout: <CustomerLayout />,
    isPrivate: true,
    isUser: true
  },
  //admin
  {
    path: RoutePath.ADMIN_LOGIN,
    Component: lazy(() => LoginAdmin)
  },
  {
    path: `${RoutePath.ADMIN_BUILDING}/*`,
    Component: lazy(() => BuildingAdmin),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: `${RoutePath.ADMIN_CUSTOMER}/*`,
    Component: lazy(() => CustomerAdmin),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: `${RoutePath.ADMIN_USER}/*`,
    Component: lazy(() => User),
    Layout: <MainLayout />,
    isPrivate: true
  }
];
